import { 
  GET_INVENTORY,
  GET_PURCHASE_ENTRY,
  GET_PURCHASE_ENTRIES,
  GET_PRODUCTS,
  GET_SUPPLIERS,
  GET_PURCHASERS
} from "../../../reducers/products/types";

import api from "../../../config/api";
import apiV2 from "../../../config/apiV2";

export const getPurchasesByPageId = (placeId, query, token, cb) => (
  dispatch,
  _
) => {
  apiV2(token)
    .get(`inventory/merchants/${placeId}/purchase-entries/`, { params: query })
    .then(resp => {
      if (resp && resp.data) {
        dispatch({
          type: GET_PRODUCTS,
          payload: resp.data
        });
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const getPurchaseById = (purchaseId, token, cb) => (
  dispatch,
  _
) => {
  apiV2(token)
    .get("/inventory/purchase-entries/" + purchaseId)
    .then(resp => {
      if (resp && resp.data) {
        dispatch({
          type: GET_PURCHASE_ENTRY,
          payload: resp.data
        });
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const addPurchase = (purchase, token, cb) => () => {
  const body = purchase;
  apiV2(token)
    .post("/inventory/purchase-entries", body)
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const updatePurchase = (purchaseId, purchase, token, cb) => () => {
  const body = purchase;
  apiV2(token)
    .patch(`/inventory/purchase-entries/${purchaseId}`, body)
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const deletePurchase = (purchaseId, token, cb) => () => {
  apiV2(token)
    .delete(`/inventory/purchase-entries/${purchaseId}`)
    .then(resp => {
      cb(null, resp);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const getPurchaseEntriesList = (query, token, cb) => () => {
  apiV2(token)
    .get("/inventory/purchase-entries/list", { params: query })
    .then(resp => {
      if (resp) {
        return cb(null, resp);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const getPurchaseEntriesListXls = (query, token, cb) => () => {
  apiV2(token)
    .get("/inventory/purchase-entries/list-xls", { params: query })
    .then(response => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const getPurchaseEntries = (placeId, query, token, cb) => (
  dispatch,
  _
) => {
  apiV2(token)
    .get(`inventory/places/${placeId}/purchase-entries`, { params: query })
    .then(response => {
      if (response && response.data) {
        dispatch({
          type: GET_PURCHASE_ENTRIES,
          payload: response.data
        });
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const getInventory = (placeId, query, token, cb) => (
  dispatch,
  _
) => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/products`, { params: query })
    .then(resp => {
      if (resp && resp.data) {
        dispatch({
          type: GET_INVENTORY,
          payload: resp.data
        });
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const getStocks = (placeId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/stock-counts`, { params: query })
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const getProductStock = (productId, query, token, cb) => () => {
  apiV2(token)
    .get("/inventory/products/" + productId + "/stock-counts/get-one-by-query", { params: query })
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const getProductById = (productId, token, cb) => () => {
  apiV2(token)
    .get("/inventory/products/" + productId)
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const getRejects = (placeId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/rejects`, { params: query })
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const addReject = (reject, token, cb) => () => {
  const body = reject;
  apiV2(token)
    .post("/inventory/rejects", body)
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const updateReject = (rejectId, reject, token, cb) => () => {
  const body = reject;
  apiV2(token)
    .patch(`/inventory/rejects/${rejectId}`, body)
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const getRejectById = (rejectId, token, cb) => () => {
  apiV2(token)
    .get("/inventory/rejects/" + rejectId)
    .then(resp => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch(error => {
      cb(error, null);
    });
};

export const getSuppliers = (query, token, cb) => (
  dispatch,
  _
) => {
  apiV2(token)
    .get("/inventory/suppliers", { params: query })
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: GET_SUPPLIERS,
          payload: res
        });
        return cb(null, res.data);
      }
      cb(res, null);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const getPurchasers = (query, token, cb) => (
  dispatch,
  _
) => {
  apiV2(token)
    .get("/inventory/purchasers", { params: query })
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: GET_PURCHASERS,
          payload: res
        });
        return cb(null, res.data);
      }
      cb(res, null);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const uploadReceiptPhoto = (data, token, cb) => () => {
  api(token)
    .post(`business/purchase-entries/receipt-photos`, data)
    .then(resp => {
      if (resp && resp.data) return cb(null, resp.data);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const removeReceiptPhoto = (purchaseEntryId, photoId, token, cb) => () => {
  api(token)
    .delete(`business/products/${purchaseEntryId}/media/${photoId}`)
    .then(resp => {
      if (resp && resp.data) return cb(null, resp.data);
    })
    .catch(error => {
      cb(error, null);
    });
};
