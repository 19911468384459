import React from "react";
import { connect } from "react-redux";
import { getSession, setSession } from "../../config/session";
import { Link } from "react-router-dom";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import queryString from "query-string";

import {
  addPurchase,
  getInventory,
  getProductById,
  getSuppliers,
  getPurchasers,
  uploadReceiptPhoto,
  removeReceiptPhoto,
  getProductStock
} from "../../layouts/Admin/actions/InventoryActions";

import { Alert, Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, 
  Input, InputGroup, InputGroupText, Label, Row, Col, Table, Modal, ModalBody, ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select"
import { startOfDay } from "date-fns";

const divPhotoStyle = {
  position: "relative",
  height: 145,
  width: 145,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 2,
  borderColor: "#666",
  borderStyle: "solid",
  borderRadius: 5
};


class AddPurchaseEntry extends React.Component {
  constructor(props) {
    const defaultPage = JSON.parse(getSession("defaultPage"));
    const userData = JSON.parse(getSession("userData"));
    super(props);
    this.state = {
      purchaseEntry: {
        receiptNumber: "",
        supplier: null,
        purchaser: null,
        totalCost: 0,
        products: [],
        miscellaneous: [],
        margin: 0,
        remarks: "",
        user: (userData && userData.userId) ? userData.userId : "",
        place: defaultPage, 
        datePurchased: startOfDay(new Date()),
        receipt: "",
        receiptPhoto: null
      },
      inventoryProducts: [],
      selectedProduct: null,
      addedProduct: null,
      placeId: defaultPage,
      suppliers: [],
      purchasers: [],
      isLoading: false,
      submitted: false,
      isUploadingReceiptPhoto: false,
      isSaving: false,
      addProductModalError: "",
      submittedModal: false,
      modal: false
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));

    if (userData == null) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const sessionToken = userData.sessionToken;
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const pageId = pageInfo && pageInfo._id ? pageInfo._id : "";
    let url = this.props.location.search;
    let query = queryString.parse(url);
    let queryStr = "?" + queryString.stringify(query);

    this.props.getInventory(pageId, queryStr, sessionToken, (err, res) => {
      if (!err && res) {
        if(res.docs && res.docs instanceof Array && res.docs.length > 0 ) {
          let products = [];
          res.docs.forEach((item) => {
            const productItem = {
              value: item.id,
              label: item.name
            }
            products.push(productItem);
          });
          this.setState({inventoryProducts: products});
        }
      }
    });

    this.props.getSuppliers("", sessionToken, (err, res) => {
      if (!err && res) {
        if(res && res instanceof Array && res.length > 0 ) {
          this.setState({suppliers: res});
        }
      }
    });

    this.props.getPurchasers("", sessionToken, (err, res) => {
      if (!err && res) {
        if(res && res instanceof Array && res.length > 0 ) {
          this.setState({purchasers: res});
        }
      }
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    if(name === "supplier") 
    {
      let supplier = null;
      if(value !== "") {
        const selected = this.state.suppliers.find(i => i.id === value)
        if(selected !== null) {
          supplier = {
            id: value,
            name: selected.name,
          }
        }
      }
      this.setState({
        purchaseEntry: {
          ...this.state.purchaseEntry,
          supplier
        }
      });
    }
    else if(name === "purchaser") 
    {
      let purchaser = null;
      if(value !== "") {
        const selected = this.state.purchasers.find(i => i.id === value)
        if(selected !== null) {
          purchaser = {
            id: value,
            lastName: selected.lastName,
            firstName: selected.firstName,
            email: selected.email
          }
        }
      }
      this.setState({
        purchaseEntry: {
          ...this.state.purchaseEntry,
          purchaser
        }
      });
    }
    else 
    {
      this.setState({
        purchaseEntry: {
          ...this.state.purchaseEntry,
          [name]: value
        }
      });
    }
  }

  handleChangeDatePurchased = (date) => {
    const datesStr = new Date(date);
    this.setState({dateStartStr: datesStr});
    this.setState({
      purchaseEntry: {
        ...this.state.purchaseEntry,
        datePurchased : datesStr
      }
    });
  }

  handleAddProduct = () => {
    const products = this.state.purchaseEntry.products;
    const addedProduct = this.state.addedProduct;
    if(addedProduct && addedProduct.id) {
      if(!addedProduct.totalCost ||
        parseFloat(addedProduct.totalCost <= 0) ||
        !addedProduct.quantity ||
        isNaN(addedProduct.quantity) ||
        addedProduct.quantity <= 0 ||
        !addedProduct.volume ||
        !addedProduct.variation
      ) {
        this.setState({
          submittedModal: true,
          addProductModalError: "Some field are missing or have invalid values"
        });
      }
      else
      {
        addedProduct.totalCost = parseFloat(addedProduct.totalCost);
        products.push(addedProduct);
        let totalCost = (products && products.length > 0) ? products.reduce((acc, cur) => acc + cur.totalCost, 0) : 0;
        const miscellaneous = this.state.purchaseEntry.miscellaneous;
        totalCost += (miscellaneous && miscellaneous.length > 0) ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0) : 0;
        this.setState({
          purchaseEntry: {
            ...this.state.purchaseEntry,
            products,
            totalCost
          },
          modal: false,
          submittedModal: false,
          addProductModalError: ""
        });
      }
    }
    else
    {
      this.setState({
        submittedModal: true,
        addProductModalError: "Please select a product"
      });
    }
  };

  handleClickRemoveProduct  = e => {
    const index = e.currentTarget.dataset.idx;
    const products = this.state.purchaseEntry.products;
    products.splice(index, 1);
    let totalCost = (products && products.length > 0) ? products.reduce((acc, cur) => acc + cur.totalCost, 0) : 0;
    const miscellaneous = this.state.purchaseEntry.miscellaneous;
    totalCost += (miscellaneous && miscellaneous.length > 0) ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0) : 0;
    this.setState({
      purchaseEntry: {
        ...this.state.purchaseEntry,
        products,
        totalCost
      },
      submitted: false
    });
  };
  
  handleChangeProduct = e => {
    let totalCost = 0;
    const { name, value } = e.target;
    const index = e.currentTarget.dataset.idx;
    const products = this.state.purchaseEntry.products;
    const miscellaneous = this.state.purchaseEntry.miscellaneous;
    totalCost += (miscellaneous && miscellaneous.length > 0) ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0) : 0;
    if(name === "totalCost") {
      const numValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0
      products[index][name] = numValue;
      this.setState({
        purchaseEntry: {
          ...this.state.purchaseEntry,
          totalCost: totalCost
        }
      });
    }
    else
    {
      products[index][name] = value;
    }
    totalCost += products.reduce((acc, cur) => acc + cur.totalCost, 0);

    this.setState({
      purchaseEntry: {
        ...this.state.purchaseEntry,
        products,
        totalCost
      }
    });
  };

  handleClickAddMiscCost = () => {
    const products = this.state.purchaseEntry.products;
    let totalCost = (products && products.length > 0) ? products.reduce((acc, cur) => acc + cur.totalCost, 0) : 0;
    const miscellaneous = this.state.purchaseEntry.miscellaneous;
    totalCost += (miscellaneous && miscellaneous.length > 0) ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0) : 0;
    miscellaneous.push({
      description: "",
      cost: 0,
    })
    this.setState({
      purchaseEntry: {
        ...this.state.purchaseEntry,
        miscellaneous,
        totalCost
      },
      
    });
  };

  handleClickRemoveMiscCost  = e => {
    const index = e.currentTarget.dataset.idx;
    const products = this.state.purchaseEntry.products;
    let totalCost = (products && products.length > 0) ? products.reduce((acc, cur) => acc + cur.totalCost, 0) : 0;
    const miscellaneous = this.state.purchaseEntry.miscellaneous;
    miscellaneous.splice(index, 1);
    totalCost += (miscellaneous && miscellaneous.length > 0) ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0) : 0;
    this.setState({
      purchaseEntry: {
        ...this.state.purchaseEntry,
        miscellaneous,
        totalCost
      },
      submitted: false
    });
  };

  handleChangeMiscCost = e => {
    const { name, value } = e.target;
    const index = e.currentTarget.dataset.idx;
    const products = this.state.purchaseEntry.products;
    const miscellaneous = this.state.purchaseEntry.miscellaneous;
    let totalCost = (products && products.length > 0) ? products.reduce((acc, cur) => acc + cur.totalCost, 0) : 0;
    if(name === "cost") {
      const numValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0
      miscellaneous[index][name] = numValue;
      totalCost += (miscellaneous && miscellaneous.length > 0) ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0) : 0;
    }
    else if(name === "quantity")
    {
      const intValue = !isNaN(parseInt(value)) ? parseInt(value) : 0
      miscellaneous[index][name] = intValue;
    }
    else
    {
      miscellaneous[index][name] = value;
    }
    this.setState({
      purchaseEntry: {
        ...this.state.purchaseEntry,
        miscellaneous,
        totalCost
      }
    });
  };

  handleSelectProduct = (e) => {
    const userData = JSON.parse(getSession("userData"));

    if(userData) {
      const sessionToken = userData.sessionToken;
      const { value: productId } = e;
      this.props.getProductById(productId, sessionToken, (err, res) => {
        if (err) {
          const { response } = err;
          let msg = "";
          if (typeof response === "string")
            msg = response;
          else
          {
            if(response.data !== null 
              && response.data.message !== null
              && typeof response.data.message === "string"
            ) {
              msg = response.data.message;
            }
          }
          this.showNotificationError(msg);
        } else {
          const product = res;
          if (e && e.value) {
            let unit = product.unit;
            if(!unit || unit === "")
              unit = (product.kind && product.kind === "crops" ) ? "kg." : "pc.";

            this.setState({
              selectedProduct: product,
              addedProduct: {
                id: product.id,
                name: product.name,
                kind: product.kind,
                quantity: 0,
                totalCost: 0,
                reorderPoint: 0,
                unit,
                volume: {},
                variation: {}
              }
            });
          }
        }
      });
    }
  }

  handleSelectVolume = (e) => {
    let { value } = e.target;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const selectedProduct = this.state.selectedProduct;
    if(selectedProduct !== null && selectedProduct.volumes !== null) {
      let isAdded = false;
      const volume = selectedProduct.volumes.find(i => i.id === value);
      let reorderPoint = 0;
      if(this.state.addedProduct.id && this.state.addedProduct.kind && this.state.addedProduct.variation.id && volume.id) {
        const products = this.state.purchaseEntry.products;
        const added = products.find(i => i.id === this.state.addedProduct.id && i.kind === this.state.addedProduct.kind && i.variation.id === this.state.addedProduct.variation.id && i.volume.id === volume.id);
        if (added) {
          isAdded = true;
          this.showNotificationError("The selected item was already added. Please select another volume");
        }
        else
        {
          let queryStr = `?kind=${this.state.addedProduct.kind}&variation=${this.state.addedProduct.variation.id}&volume=${volume.id}`;
          const query = queryString.parse(queryStr);
          this.props.getProductStock(this.state.addedProduct.id, query, sessionToken, (_, res) => {
            if(res && res.product && res.product.reorderPoint)
              reorderPoint = res.product.reorderPoint;
            this.setState({
              addedProduct: {
                ...this.state.addedProduct,
                reorderPoint
              }
            });
          });
        }
      }
      if(!isAdded) {
        this.setState({
          addedProduct: {
            ...this.state.addedProduct,
            volume
          }
        });
      }
    }
  }

  handleSelectVariation = (e) => {
    let { value } = e.target;
    const selectedProduct = this.state.selectedProduct;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    if(selectedProduct !== null && selectedProduct.variations !== null) {
      let isAdded = false;
      const variation = selectedProduct.variations.find(i => i.id === value)
      let reorderPoint = 0;
      if(this.state.addedProduct.id && this.state.addedProduct.kind && variation.id && this.state.addedProduct.volume.id) {
        const products = this.state.purchaseEntry.products;
        const added = products.find(i => i.id === this.state.addedProduct.id && i.kind === this.state.addedProduct.kind && i.variation.id === variation.id && i.volume.id === this.state.addedProduct.volume.id);
        if (added) {
          isAdded = true;
          this.showNotificationError("The selected item was already added. Please select another variation");
        }
        else
        {
          let queryStr = `?kind=${this.state.addedProduct.kind}&variation=${variation.id}&volume=${this.state.addedProduct.volume.id}`;
          const query = queryString.parse(queryStr);
          this.props.getProductStock(this.state.addedProduct.id, query, sessionToken, (_, res) => {
            if(res && res.product && res.product.reorderPoint)
              reorderPoint = res.product.reorderPoint;
            this.setState({
              addedProduct: {
                ...this.state.addedProduct,
                reorderPoint
              }
            });
          });
        }
      }
      if(!isAdded) {
        this.setState({
          addedProduct: {
            ...this.state.addedProduct,
            variation
          }
        });
      }
    }
  }

  handleChangeAddProduct = (e) => {
    let { name, value } = e.target;
    if(name === "quantity" || name === "reorderPoint")
    {
      const intValue = !isNaN(parseInt(value)) ? parseInt(value) : 0
      this.setState({
        addedProduct: {
          ...this.state.addedProduct,
          [name]: intValue
        }
      });
    }
    else
    {
      this.setState({
        addedProduct: {
          ...this.state.addedProduct,
          [name]: value
        }
      });
    }
  }

  handleReceiptImageUpload = (e) => {
    const userData = JSON.parse(getSession("userData"));
    const files = e.target.files;

    if(files.length>1) {
      this.setState({isUploadingReceiptPhoto:false });
      console.log("No. of files: ",files.length);
      alert("You are only allowed to upload one(1) file!");
    }
    else
    {
      this.setState({isUploadingReceiptPhoto: true});
      const sessionToken = userData.sessionToken;
      const formData = new FormData();
      formData.append("file", files[0]);
      
      this.props.uploadReceiptPhoto(formData, sessionToken, (error, result) => {
        if (!error && result) {
          this.setState({ isUploadingReceiptPhoto: false });
          const photo = result;
          if(photo.original) {
            this.setState({
              purchaseEntry: {
                ...this.state.purchaseEntry,
                receipt: photo.original,
                receiptPhoto: photo
              }
            });
          }
        } else {
          this.setState({ isUploadingReceiptPhoto:false });
          if (error.response && typeof error.response === "string") {
            console.error(error.response);
            this.showNotificationError(error.response);
          } else {
            console.error({error});
            this.showNotificationError("There is an error uploading the file. Please try again");
          }
        }
      });
    }
  }

  handleRemoveReceiptImage = (e) => {
    if (!window.confirm("Do you want to remove this image?")) {
      return false;
    }

    this.setState({
      purchaseEntry: {
        ...this.state.purchaseEntry,
        receipt: "",
        receiptPhoto: null
      }
    });
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    this.setState({
      selectedProduct:null,
      addedProduct: null,
      addProductModalError: ""
    });
  }

  showNotification(message) {
    if(message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>
              {message}
            </div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 5
      }
      this.refs.notify.notificationAlert(notification);
    }
  }

  showNotificationError(message) {
    if(message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>
              {message}
            </div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 5
      }
      this.refs.notify.notificationAlert(notification);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    const defaultPage = JSON.parse(getSession("defaultPage"));

    if(userData) {
      const sessionToken = userData.sessionToken;
      const purchaseEntry = {...this.state.purchaseEntry};
      let hasError = false;
      if (
        purchaseEntry.receiptNumber &&
        purchaseEntry.totalCost &&
        !isNaN(purchaseEntry.totalCost) &&
        purchaseEntry.totalCost >= 0 &&
        !isNaN(purchaseEntry.margin) &&
        purchaseEntry.datePurchased
      ) {

        this.setState({ submitted: true });

        purchaseEntry.products.forEach(item => {
          if(!item.quantity || !item.totalCost) {
            hasError = true;
          }
        });

        purchaseEntry.miscellaneous.forEach(item => {
          if(!item.cost) {
            hasError = true;
          }
        });

        if(!hasError) {

          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }

          this.setState({ isSaving: true });

          if (purchaseEntry.supplier === null)
            delete purchaseEntry.supplier
          if (purchaseEntry.purchaser === null)
            delete purchaseEntry.purchaser
          if (!purchaseEntry.user) 
            purchaseEntry.user = userData.userId
          if (!purchaseEntry.place)
            purchaseEntry.place = defaultPage
          if(purchaseEntry.margin !== null)
            purchaseEntry.margin = parseFloat(purchaseEntry.margin) 

          this.props.addPurchase(purchaseEntry, sessionToken, (err, res) => {
            if (res) {
              setSession("hasProducts",true);
              this.setState({ submitted: false, isSaving: false });
              if(res.id) {
                this.showNotification("Purchase entry saved. Please check you product's settings for the updated price list");
                setTimeout(() => {
                  this.props.history.push("/inventory-purchases");
                }, 3000);
              }
            } else {
              if (err) {
                this.setState({ submitted: false, isSaving: false });
                const { response } = err;
                let msg = "";
                if (typeof response === "string")
                  msg = response;
                else
                {
                  if(response.data !== null 
                    && response.data.message !== null
                    && typeof response.data.message === "string"
                  ) {
                    msg = response.data.message;
                  }
                }
                this.showNotificationError(msg);
              } else {
                this.setState({ submitted: false, isSaving: false });
                this.showNotificationError("An unknown error occured. Please try again.");
              }
            }
          });
        }
      } else {
        this.setState({ submitted: true });
        if(isNaN(purchaseEntry.totalCost) || isNaN(purchaseEntry.margin)) {
          this.setState({ submitted: true });
          if(isNaN(purchaseEntry.totalCost)) {
            this.showNotificationError("Invalid number value for Total Cost");
          }
          if(isNaN(purchaseEntry.margin)) {
            this.showNotificationError("Invalid number value for Margin");
          }
        } else {
          this.showNotificationError("Some fields are required. Please fill in the required fields");
        }
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  }

  onKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  renderSuppliers() {
    if (this.state.suppliers !== null && this.state.suppliers.length > 0) {
      const suppliers = this.state.suppliers.filter(item => item.id !== null);
      return suppliers.map((item, index) => (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      ));
    }
  }

  renderPurchasers() {
    if (this.state.purchasers !== null && this.state.purchasers.length > 0) {
      const purchasers = this.state.purchasers.filter(item => item.id !== null);
      return purchasers.map((item, index) => (
        <option key={index} value={item.id}>
          {item.firstName} {item.lastName}
        </option>
      ));
    }
  }
  
  renderVolumes() {
    if (
      this.state.selectedProduct !== null && 
      this.state.selectedProduct.volumes !== null
    ) {
      const volumes = this.state.selectedProduct.volumes;
      return volumes.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }

  renderVariations() {
    if (
      this.state.selectedProduct !== null && 
      this.state.selectedProduct.variations !== null
    ) {
      const variations = this.state.selectedProduct.variations;
      return variations.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }

  renderPurchaseEntryForm(purchaseEntry) {
    return(
      <>
        <Row>
          <Col lg="6" md="6" sm="12">
            <Row>
              <Col lg="12" md="12" sm="12">
                <FormGroup>
                  <Alert color="primary">
                    Add purchase details.
                  </Alert>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="12">
                <FormGroup className={this.state.submitted && !purchaseEntry.receiptNumber ? " has-danger" : ""}>
                  <Label htmlFor="receiptNumber" className="control-label">
                    Receipt No. <em className="text-muted">(Required)</em>
                  </Label>
                  <Input
                    id="receiptNumber"
                    name="receiptNumber"
                    className="receiptNumber"
                    placeholder="Receipt No."
                    type="text"
                    value={purchaseEntry.receiptNumber}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="12">
                <FormGroup>
                  <Label htmlFor="datePurchased" className="control-label"  style={{display:"block"}}>
                    Date Purchased
                  </Label>
                  <DatePicker
                    id="datePurchased"
                    name="datePurchased"
                    className="form-control"
                    selectsStart
                    startDate={purchaseEntry.datePurchased}
                    selected={purchaseEntry.datePurchased}
                    onChange={this.handleChangeDatePurchased}
                    dateFormat="MMMM d, yyyy"
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="12">
                <FormGroup className={this.state.submitted && !purchaseEntry.supplier ? " has-danger" : ""}>
                  <Label htmlFor="supplier" className="control-label">
                    Supplier <em className="text-muted">(Required)</em>
                  </Label>
                  <Input
                    id="supplier"
                    name="supplier"
                    className="supplier"
                    placeholder="Supplier"
                    type="select"
                    value={(purchaseEntry.supplier !== null && purchaseEntry.supplier.id !== null) ? purchaseEntry.supplier.id : "" }
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    {this.renderSuppliers()}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="12">
                <FormGroup className={this.state.submitted && !purchaseEntry.purchaser ? " has-danger" : ""}>
                  <Label htmlFor="purchaser" className="control-label">
                    Purchaser <em className="text-muted">(Required)</em>
                  </Label>
                  <Input
                    id="purchaser"
                    name="purchaser"
                    className="purchaser"
                    placeholder="Purchaser"
                    type="select"
                    defaultValue={(purchaseEntry.supplier !== null && purchaseEntry.supplier.id !== null) ? purchaseEntry.supplier.id : "" }
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    {this.renderPurchasers()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="12">
                <FormGroup
                  className={
                    this.state.submitted && (isNaN(purchaseEntry.totalCost) || purchaseEntry.totalCost <= 0)
                      ? " has-danger"
                      : ""
                  }
                >
                  <p className="text-navy" style={{  fontWeight: "600", textDecoration: "underline" }}>
                    Total Cost: &#8369;{numberWithCommas(purchaseEntry.totalCost)}
                  </p>
                  {this.state.submitted && (isNaN(purchaseEntry.totalCost) || purchaseEntry.totalCost <= 0) && <Alert size="sm" color="danger">Total cost should not be 0</Alert>}
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col lg="6" md="6" sm="12">
            <FormGroup className={this.state.submitted && !purchaseEntry.receipt ? " has-danger" : ""} style={{width: "150px"}}>
              <Label htmlFor="receipt" className="control-label">Upload Receipt</Label>
              <div className="upload-photo" style={{ margin: "10px auto", border: "1px solid #ccc", "backgroundColor": "#efefef" }}>
                <Input id="receipt" name="receipt" label="upload file" type="file" onChange={this.handleReceiptImageUpload} style={divPhotoStyle} />
                  <ul className="product-photos">
                    {purchaseEntry.receipt && purchaseEntry.receiptPhoto && purchaseEntry.receiptPhoto.thumb &&
                      <li key={purchaseEntry.receipt} >
                        <img alt={purchaseEntry.receipt} title={purchaseEntry.receipt} src={purchaseEntry.receiptPhoto.thumb}/>
                        <button data-id={purchaseEntry.receipt} type="button" onClick={this.handleRemoveReceiptImage} className="remove-image"><span style={{color:"#fefefe"}}>&times;</span></button>
                      </li>
                    }
                  </ul>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <p className="control-label">ITEMS PURCHASED</p>
              <Row>
                <Col sm="12" md="12" lg="10">
                  <Table style={{minWidth:"600px"}} className="tablesorter table-hover" responsive size="sm">
                    <thead className="text-primary">
                      <tr>
                        <th>Item Name</th>
                        <th>Volume</th>
                        <th>Variation</th>
                        <th>Quantity</th>
                        <th>Cost</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseEntry.products.length > 0 ?
                        purchaseEntry.products.map((item, i) => {
                          return (
                            <tr key={i} >
                              <td>
                                <FormGroup className={this.state.submitted && !item.name ? " has-danger" : ""}>
                                  <Input
                                    name="name"
                                    readOnly
                                    className="name"
                                    placeholder="Item Name"
                                    type="text"
                                    defaultValue={item.name}
                                    data-idx={i}
                                    onChange={this.handleChangeProduct}
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={this.state.submitted && !item.volume && !item.volume.description ? " has-danger" : ""}>
                                  <Input
                                    name="volume"
                                    readOnly
                                    className="volume"
                                    placeholder="Volume"
                                    type="text"
                                    defaultValue={item.volume.description}
                                    data-idx={i}
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={this.state.submitted && !item.variation && !item.variation.description ? " has-danger" : ""}>
                                  <Input
                                    name="variation"
                                    readOnly
                                    className="variation"
                                    placeholder="Variation"
                                    type="text"
                                    defaultValue={item.variation.description}
                                    data-idx={i}
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={this.state.submitted && (!item.quantity || isNaN(item.quantity)) ? " has-danger" : ""}>
                                  <InputGroup>
                                    <Input
                                      name="quantity"
                                      className="quantity"
                                      placeholder="Quantity"
                                      type="text"
                                      defaultValue={item.quantity ? item.quantity : 0}
                                      data-idx={i}
                                      onChange={this.handleChangeProduct}
                                    />
                                    <InputGroupText className={this.state.submitted && (!item.quantity || isNaN(item.quantity)) ? "has-danger" : ""}>
                                      {item.unit}
                                    </InputGroupText>
                                  </InputGroup>
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={this.state.submitted && (!item.totalCost || isNaN(item.totalCost)) ? " has-danger" : ""}>
                                  <InputGroup>
                                    <InputGroupText className={this.state.submitted && (!item.totalCost || isNaN(item.totalCost)) ? "has-danger" : ""}>
                                      &#8369;
                                    </InputGroupText>
                                    <Input
                                      name="totalCost"
                                      className="totalCost"
                                      placeholder="Cost"
                                      type="text"
                                      defaultValue={item.totalCost ? item.totalCost : 0}
                                      data-idx={i}
                                      onChange={this.handleChangeProduct}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </td>
                              <td>
                                <Button
                                  alt="Remove"
                                  title="Remove"
                                  style={{ marginRight: "10px"}} 
                                  className="btn-round btn-sm" 
                                  color="danger" 
                                  type="button"
                                  data-idx={i}
                                  onClick={this.handleClickRemoveProduct}>
                                  <Fa icon="times"/>
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                        :
                          <>
                            <tr>
                              <td colSpan={6}>
                                <h5 className="text-danger">
                                  <em>No products added.</em>
                                </h5>
                              </td>
                            </tr>
                          </>
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Button
                alt="Add"
                title="Add"
                className="btn-round btn-sm" 
                color="info" 
                type="button" 
                onClick={this.toggleModal}>
                <Fa icon="plus"/>&nbsp; Add Item
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <p className="control-label">MISCELLANEOUS COST</p>
              <Row>
                <Col sm="12" md="12" lg="10">
                  <Table style={{minWidth:"600px"}} className="tablesorter table-hover" responsive size="sm">
                    <thead className="text-primary">
                      <tr>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseEntry.miscellaneous.length > 0 ?
                        purchaseEntry.miscellaneous.map((item, i) => {
                          return (
                            <tr key={i} >
                              <td>
                                <FormGroup style={{ width:"100%", minWidth:"300px"}} className={this.state.submitted && !item.description ? " has-danger" : ""}>
                                  <Input
                                    name="description"
                                    className="description"
                                    placeholder="Description"
                                    type="text"
                                    defaultValue={item.description}
                                    data-idx={i}
                                    onChange={this.handleChangeMiscCost}
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={this.state.submitted && (!item.cost || isNaN(item.cost)) ? " has-danger" : ""}>
                                  <InputGroup>
                                    <InputGroupText className={this.state.submitted && (!item.cost || isNaN(item.cost)) ? "has-danger" : ""}>
                                      &#8369;
                                    </InputGroupText>
                                    <Input
                                      name="cost"
                                      className="cost"
                                      placeholder="Cost"
                                      type="text"
                                      defaultValue={item.cost}
                                      data-idx={i}
                                      onChange={this.handleChangeMiscCost}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </td>
                              <td>
                                <Button
                                  alt="Remove"
                                  title="Remove"
                                  style={{ marginRight: "10px"}} 
                                  className="btn-round btn-sm" 
                                  color="danger" 
                                  type="button"
                                  data-idx={i}
                                  onClick={this.handleClickRemoveMiscCost}>
                                  <Fa icon="times"/>
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                        :
                        <>
                          <tr>
                            <td colSpan={4}>
                              <h5 className="text-danger">
                                <em>No items added.</em>
                              </h5>
                            </td>
                          </tr>
                        </>
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Button
                alt="Add"
                title="Add"
                className="btn-round btn-sm" 
                color="info" 
                type="button" 
                onClick={this.handleClickAddMiscCost}>
                <Fa icon="plus"/>&nbsp; Add Item
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6" sm="12">
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="remarks" className="control-label" >Remarks</Label>
                  <Input
                    id="remarks"
                    name="remarks"
                    placeholder="Remarks"
                    type="textarea"
                    value={purchaseEntry.remarks}
                    onChange={this.handleChange}
                    style={{height:"150px"}}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6" sm="12">
            <Row>
              <Col md="6">
                <FormGroup
                  className={
                    this.state.submitted && (isNaN(purchaseEntry.margin) || purchaseEntry.margin < 0)
                    ? " has-danger"
                    : ""
                  }
                  >
                  <Label htmlFor="margin" className="control-label">
                    Margin<em className="text-muted">(%)</em>
                  </Label>
                  <Input
                    id="margin"
                    name="margin"
                    className="margin"
                    placeholder="margin"
                    type="text"
                    value={purchaseEntry.margin}
                    onChange={this.handleChange}
                    />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "} <Link to="/add-page">here</Link> {" "}to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageNotVerified() {
    return(
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">You Have Not Agreed with our Terms & Policies</h4>
                  <hr />
                  <p className="mb-0">
                    You must agree  with our Terms & Policies. Click {" "} <Link to="/statement-of-agreement">here</Link> {" "} to read our Terms & Policies.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageDisabled() {
    return(
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">This page is disabled.</h4>
                  <hr />
                  <p className="mb-0">
                    Click {" "} <Link to="/inventory-purchases">here</Link> {" "} to go back to the main page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let pageTitle = "Add New Purchase Entry"
    let { pageType } = this.state;
    let categoriesTree = [];
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const productCategories = this.state.productCategories;
    if(productCategories instanceof Array && productCategories.length > 0) {
      const parentCategories = productCategories.filter(item => !item.parent);
      const childCategories = productCategories.filter(item => item.parent);
      if (parentCategories) {
        parentCategories.forEach(cat => {
          const value = cat._id.toString();
          const label = cat.name;
          let sublist = [];
          if(pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") { // If Grocery
            sublist = childCategories.filter( item => item.parent._id.toString() === value );
          } else {
            sublist = childCategories.filter( item => item.parent._id.toString() === value );
          }
          let children1 = [];
          if(sublist instanceof Array && sublist.length > 0) {
            let category1 = [];
            sublist.forEach(cat => {
              const value = cat._id;
              const label = cat.name;
              const category = {
                value: value,
                label: label,
                children: [],
              }
              category1.push(category);
            });
            children1 = category1;
          }
          const children = children1;
          const category = {
            value: value,
            label: label,
            children: children,
          }
          categoriesTree.push(category);
        });
      }
    }

    if(pageInfo && pageInfo._id) {
      if(pageInfo.isVerified === true) {
        if(pageType !== "sb-tours") {
          return (
            <>
              <div className="content">
                <div className="react-notification-alert-container">
                  <NotificationAlert ref="notify" />
                </div>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Card>
                      <Form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress}>
                        <CardHeader>
                          <h4 className="title">{pageTitle}</h4>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col sm="12">
                              {this.renderPurchaseEntryForm(this.state.purchaseEntry)}
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <Button className="btn-round" color="info" type="submit">
                            Save
                          </Button>
                          <Link to="/inventory-purchases" className="btn btn-round btn-light">
                            Cancel
                          </Link>
                        </CardFooter>
                      </Form>
                    </Card>
                  </Col>
                </Row>
                <LoadingOverlay
                  active={this.state.isSaving}
                  spinner
                  text="Saving..."
                  >
                </LoadingOverlay>
                <div className="modal-section">
                  <Modal isOpen={this.state.modal} toggle={this.toggleModal} backdrop="static" className={this.props.className} size="lg">
                    <div className="modal-header">
                      <h4 className="modal-title">Add Product to Purchase</h4>
                      <button type="button" className="close" onClick={this.toggleModal} aria-label="Close" style={{color:"rgba(0, 0, 0, 0.6)"}}><span aria-hidden="true">×</span></button>
                    </div>
                    <ModalBody>
                      <form action="">
                        <Row>
                          <Col sm="12" md="6" lg="6">
                            <FormGroup className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.id) ? "has-danger" : ""}>
                              {this.state.addProductModalError && <Alert color="danger">{this.state.addProductModalError}</Alert>}
                              <Select
                                className="react-select"
                                options={this.state.inventoryProducts}
                                onChange={this.handleSelectProduct}
                                placeholder="Select a product"
                                value={this.state.inventoryProducts.filter(item => item.value === (this.state.selectedProduct && this.state.selectedProduct.id ? this.state.selectedProduct.id : ""))}
                                />
                            </FormGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <FormGroup>
                              <p className="control-label">Kind:&nbsp;{this.state.addedProduct && this.state.addedProduct.kind ? capitalizeFirstLetter(this.state.addedProduct.kind) : "Goods"}</p>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="12" md="6" lg="6">
                            <FormGroup className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.volume || !this.state.addedProduct.volume.id) ? " has-danger" : ""}>
                              <Label htmlFor="volume" className="control-label">Volume</Label>
                              <Input
                                id="volume"
                                name="volume"
                                className="volume"
                                placeholder="Volume"
                                type="select"
                                value={this.state.addedProduct && this.state.addedProduct.volume && this.state.addedProduct.volume.id ? this.state.addedProduct.volume.id : ""}
                                onChange={this.handleSelectVolume}
                              >
                                <option value="">Select one</option>
                                {this.renderVolumes()}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <FormGroup className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.variation || !this.state.addedProduct.variation.id) ? " has-danger" : ""}>
                              <Label htmlFor="variation" className="control-label">Variation</Label>
                              <Input
                                id="variation"
                                name="variation"
                                className="variation"
                                placeholder="Variation"
                                type="select"
                                value={this.state.addedProduct && this.state.addedProduct.variation && this.state.addedProduct.variation.id ? this.state.addedProduct.variation.id : ""}
                                onChange={this.handleSelectVariation}
                              >
                                <option value="">Select one</option>
                                {this.renderVariations()}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <FormGroup className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.quantity || isNaN(this.state.addedProduct.quantity)) ? " has-danger" : ""}>
                              <Label htmlFor="quantity" className="control-label">Quantity</Label>
                              <InputGroup>
                                <Input
                                  id="quantity"
                                  name="quantity"
                                  className="quantity"
                                  placeholder="Qty."
                                  type="text"
                                  value={(this.state.addedProduct && this.state.addedProduct.quantity) ? this.state.addedProduct.quantity : 0}
                                  onChange={this.handleChangeAddProduct}
                                  />
                                  <InputGroupText className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.quantity || isNaN(this.state.addedProduct.quantity)) ? " has-danger" : ""}>
                                    {(this.state.addedProduct && this.state.addedProduct.unit) ? this.state.addedProduct.unit : "pc."}
                                  </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <FormGroup className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.totalCost || isNaN(this.state.addedProduct.totalCost)) ? " has-danger" : ""}>
                              <Label htmlFor="totalCost" className="control-label">
                                Total Cost
                              </Label>
                              <InputGroup>
                                <InputGroupText className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.totalCost || isNaN(this.state.addedProduct.totalCost)) ? " has-danger" : ""}>
                                  &#8369;
                                </InputGroupText>
                                <Input
                                  id="totalCost"
                                  name="totalCost"
                                  className="totalCost"
                                  placeholder="Total Cost"
                                  type="text"
                                  value={(this.state.addedProduct && this.state.addedProduct.totalCost) ? this.state.addedProduct.totalCost : 0}
                                  onChange={this.handleChangeAddProduct}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="12" md="6" lg="6">
                            <FormGroup className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.reorderPoint || isNaN(this.state.addedProduct.reorderPoint)) ? " has-danger" : ""}>
                              <Label htmlFor="totalCost" className="control-label">
                                Reorder Point
                              </Label>
                              <InputGroup>
                                <Input
                                  id="reorderPoint"
                                  name="reorderPoint"
                                  className="reorderPoint"
                                  placeholder="Reorder Point"
                                  type="text"
                                  value={(this.state.addedProduct && this.state.addedProduct.reorderPoint) ? this.state.addedProduct.reorderPoint : 0}
                                  onChange={this.handleChangeAddProduct}
                                />
                                <InputGroupText className={this.state.submittedModal && (!this.state.addedProduct || !this.state.addedProduct.reorderPoint || isNaN(this.state.addedProduct.reorderPoint)) ? " has-danger" : ""}>
                                  {(this.state.addedProduct && this.state.addedProduct.unit) ? this.state.addedProduct.unit : "pc."}
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </form>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="info" onClick={this.handleAddProduct}>Add Product</Button>{" "}
                      <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </>
          );
        } else if(pageType && pageType === "sb-tours") {
          return (this.renderPageDisabled());
        }
      } else {
        return(this.renderPageNotVerified());
      }
    } else {
      return(this.renderNoPageAdded());
    }
  }
}

const numberWithCommas = x => {
  return priceRound(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const priceRound = (price, dec) => {
  if (dec === undefined) {
    dec = 2;
  }
  if (price !== 0) {
    if (!price || isNaN(price)) {
      throw new Error("price is not a number" + price);
    }
  }
  const str = parseFloat(Math.round(price * 100) / 100).toFixed(dec);

  return str;
};

const capitalizeFirstLetter = (str) => {
  if(typeof str === "string" && str.length > 0) {
    return str.replace(/^./, str[0].toUpperCase())
  }
}

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {
    addPurchase,
    getInventory,
    getProductById,
    getSuppliers,
    getPurchasers,
    uploadReceiptPhoto,
    removeReceiptPhoto,
    getProductStock
  }
)(AddPurchaseEntry);
