import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSession, setSession } from "../../config/session";
import api from "../../config/api";
import {
  getStocks,
} from "../../layouts/Admin/actions/InventoryActions";
import queryString from "query-string";
import Pagination from "react-js-pagination";
import { PulseLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from 'react-loading-overlay';

import defaultProductImage from "../../assets/img/product-image-default.png";

import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Row,
  Col,
  Media,
  FormGroup,
  Input,
} from "reactstrap";

class InventoryLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      checked: [],
      defaultPage: "",
      pagination: {},
      activePage: 1,
      selectedPage: "",      
      pageName: "",
      sortBy: "",
      sort: "",
      keyword: "",
      stockStatus: "",
      kind: "",
      size: 25,
      isLoading: true,
      isProcessing: false,
      isGeneratingXls: false,
      isGrocery: false,
      hideTutorials: false
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if(pageInfo && pageInfo.pageType === '5cd141d10d437be772373ddb') {
      this.setState({isGrocery:true});
    }

    if (userData !== null) {
      let defaultPage = JSON.parse(getSession("defaultPage"));
      const sessionToken = userData.sessionToken;
    
      if (defaultPage) {
        if (defaultPage !== "") {
          let url = this.props.location.search;
          let query = queryString.parse(url);
          let activePage = query.page ? Number(query.page) : 1;
          let status = query.status ? query.status : "";
          let sortBy = query.sortBy ? query.sortBy : "created";
          let sort = query.sort ? query.sort : "desc";
          let keyword = query.keyword ? query.keyword : "";
          let size = query.size ? query.size : 25;
          let kind = query.kind ? query.kind : "";
          let stockStatus = query.stockStatus ? query.stockStatus : "";
          if(query.category) {
            
          } else {
            delete query.subCategory;
          }
          let queryStr = "?" + queryString.stringify(query);
          this.setState({
            activePage: activePage,
            selectedPage: defaultPage,
            status: status,
            sort: sort,
            sortBy: sortBy,
            keyword: keyword,
            kind,
            stockStatus,
            size,
            pageName: pageInfo && pageInfo.name ? pageInfo.name : '',
            hideTutorials: (pageInfo && pageInfo.hideTutorials && pageInfo.hideTutorials === true) ? true : false
          });
          this.refreshList(queryStr, defaultPage);
        } else {
          this.props.getProviderPlaces(sessionToken, (error, result) => {
            if (!error && result) {
              if(result.places && result.places instanceof Array && result.places.length > 0 ) {
                const defaultPlace = result.places.find(item => item.isDefault);
                if(defaultPlace && defaultPlace._id) {
                  defaultPage = defaultPlace._id;
                  pageInfo = defaultPlace;
                } else {
                  defaultPage = result.places[0]._id;
                  pageInfo = result.places[0];
                }
              }
              setSession('pageInfo',JSON.stringify(pageInfo));
              let url = this.props.location.search;
              let query = queryString.parse(url);
              let activePage = query.page ? Number(query.page) : 1;
              let status = query.status ? query.status : "";
              let sortBy = query.sortBy ? query.sortBy : "created";
              let sort = query.sort ? query.sort : "desc";
              let keyword = query.keyword ? query.keyword : "";
              let size = query.size ? query.size : 25;
              let kind = query.kind ? query.kind : "";
              let stockStatus = query.stockStatus ? query.stockStatus : "all";
              let queryStr = "?" + queryString.stringify(query);
              this.setState({
                activePage: activePage,
                selectedPage: defaultPage,
                status: status,
                sort: sort,
                sortBy: sortBy,
                keyword: keyword,
                kind,
                stockStatus,
                size: size,
                pageName: pageInfo && pageInfo.name ? pageInfo.name : ''
              });
              if(pageInfo && pageInfo.pageType === '5cd141d10d437be772373ddb') {
                this.setState({isGrocery:true});
              }
              this.refreshList(queryStr, defaultPage);
            } else {
              this.setState({ isLoading: false });
            }
          });
        }
      } else {
        this.props.getProviderPlaces(sessionToken, (error, result) => {
          if (!error && result) {
            if(result.places && result.places instanceof Array && result.places.length > 0 ) {
              const defaultPlace = result.places.find(item => item.isDefault);
              if(defaultPlace && defaultPlace._id) {
                defaultPage = defaultPlace._id;
                pageInfo = defaultPlace;
              } else {
                defaultPage = result.places[0]._id;
                pageInfo = result.places[0];
              }
              setSession('pageInfo',JSON.stringify(pageInfo));
              let url = this.props.location.search;
              let query = queryString.parse(url);
              let activePage = query.page ? Number(query.page) : 1;
              let status = query.status ? query.status : "";
              let sortBy = query.sortBy ? query.sortBy : "created";
              let sort = query.sort ? query.sort : "desc";
              let keyword = query.keyword ? query.keyword : "";
              let size = query.size ? query.size : 25;
              let kind = query.kind ? query.kind : "";
              let stockStatus = query.stockStatus ? query.stockStatus : "all";
              let queryStr = "?" + queryString.stringify(query);
              this.setState({
                activePage: activePage,
                selectedPage: defaultPage,
                status: status,
                sort: sort,
                sortBy: sortBy,
                keyword: keyword,
                kind,
                stockStatus,
                size: size,
                pageName: pageInfo.name
              });
              if(pageInfo && pageInfo.pageType === '5cd141d10d437be772373ddb') {
                this.setState({isGrocery:true});
              }
              this.refreshList(queryStr, defaultPage);
            } else {
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({ isLoading: false });
          }
        });
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
    this._isMount = true;
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  renderRows(stocks) {
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let colSpan = 12;
    if(pageInfo && pageInfo.pageType === '5cd141d10d437be772373ddb') {
      colSpan = 13;
    }
    if(this.state.isLoading) {
      return (
        <tr>
          <td colSpan={colSpan}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={'#1d8cf8'}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (
        stocks instanceof Array &&
        stocks.length > 0
      ) {
        return stocks.map((item, index) => (
          <tr key={index}>
            <td className="text-medium">{numberWithCommasOnly(index+1+((this.state.activePage-1)*this.state.size))}&middot;</td>
            <td width="500">
              <Link 
                to={ "/products/" + item.product.id + "/edit" +
                  ((this.state.activePage > 0 ||
                    this.state.keyword !== '' ||
                    this.state.status !== '')
                    ? '?' : '') +
                    (this.state.activePage > 0 ? '&page=' + this.state.activePage : '') +
                    (this.state.keyword !== '' ? '&keyword=' + this.state.keyword : '') +
                    (this.state.status !== ''  ? '&status=' + this.state.status : '')
                  }
                className="text-medium" 
                alt={item.product.name}
                title={item.product.name}
              >
                {item.product.name} -
              </Link>
              <em>({item.product.volume.description}/{item.product.variation.description})</em>
            </td>
            <td className="text-medium">
              <span className={
                item.status && item.status === "underStock" ? "text-warning" :
                item.status && item.status === "outOfStock" ? "text-danger" :
                "text-success"
              }>
                {numberWithCommasOnly(item.stockCount)}&nbsp;
                <em>{item.product.unit ? item.product.unit : "pc."}</em>
              </span>
            </td>
            <td className="text-medium">
              {numberWithCommasOnly(item.product.reorderPoint)}&nbsp;
              <em>{item.product.unit ? item.product.unit : "pc."}</em>
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={colSpan}>
              <h5 className="text-danger">
                <em>No products found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderCategories(categories) {
    if(categories instanceof Array && categories.length > 0) {
      if(categories.length === 1) {
        return categories.map(cat => cat.name);
      } else {
        return categories.map(cat => cat.name + ", ");
      }
    }
  }

  renderImage(item) {
    const {
      name,
      photos,
    } = item;

    let primaryPhoto;
    if (photos[0] !== undefined || photos[0] != null) {
      primaryPhoto = photos[0];
    } else {
      primaryPhoto = {
        thumb: defaultProductImage,
      }
    }
    return (
      <Media className="mt-1">
        { primaryPhoto && primaryPhoto.thumb &&
          <Media left middle>
            <Media
              object
              data-src={primaryPhoto.thumb}
              src={primaryPhoto.thumb}
              alt={name}
              title={name}
            />
          </Media>
        }
      </Media>
    );
  }

  renderPlaces() {
    if (this.state.places !== undefined || this.state.places != null) {
      return this.state.places.map((item, index) => (
        <option key={index} value={item._id.toString()}>
          {item.name}
        </option>
      ));
    }
  }

  toggleSwitchIsActive(item) {
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const itemId = item.id;
    if (item) {
      this.props.updateProduct(
        item,
        itemId,
        sessionToken,
        (error, result) => {
          if (result) {
            this.showNotification('Product successfully updated.');
          } else {
            if (error) {
              this.showNotificationError(error.response);
            } else {
              this.showNotificationError('Product not updated.');
            }
          }
        }
      );
    }
  }

  onDismiss = () => {
    this.setState({ hideTutorials: true });
    let pageInfo = JSON.parse(getSession("pageInfo"));
    pageInfo.hideTutorials = true;
    setSession('pageInfo',JSON.stringify(pageInfo));
  }

  onHideTutorials = () => {
    if (!window.confirm("Are you sure you want to hide this section permanently?")) {
      return false;
    }
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const place = { 
      hideTutorials: true
    }
    api(sessionToken).patch(`/provider/places/${pageInfo._id}`, place)
      .then(response => {
        if (response && response.data && response.data.status === 'ok') {
          this.setState({ hideTutorials: true });
          pageInfo.hideTutorials = true;
          setSession('pageInfo',JSON.stringify(pageInfo));
        } else {
          this.showNotificationError('An unknown error occured. Please try again!');
        }
      })
      .catch(error => {
        this.setState({ submitted: false, isSaving: false });
        if(error.response && typeof error.response === 'string' ) {
          this.showNotificationError(error.response);
        } else {
          this.showNotificationError('An unknown error occured. Please try again!');
        }
      });
  }

  showNotification(message) {
    if(message && typeof message === 'string') {
      const notification = {
        place: 'tc',
        message: (
          <div>
            <div>
              {message}
            </div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 2
      }
      if(notification && this.refs.notify && this.refs.notify.notificationAlert) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  showNotificationError(message) {
    if(message && typeof message === 'string') {
      const notification = {
        place: 'tc',
        message: (
          <div>
            <div>
              {message}
            </div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 2
      }
      if(notification && this.refs.notify && this.refs.notify.notificationAlert) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  handleChangeKeyword = (e) => {
    let { value } = e.target;
    this.setState({keyword: value});
  }

  handleEnter = (e) => {
    let { key } = e;
    if (key === 'Enter') {
      this.setState({ isLoading:true, activePage:1 });
      let { keyword } = this.state;

      let defaultPage = JSON.parse(getSession("defaultPage"));
      let url = this.props.location.search;
      let query = queryString.parse(url);
      delete query.message;
      delete query.page;
      if (keyword !== "") {
        query.keyword = keyword.trim();
      } else {
        delete query.keyword;
      }
      let queryStr = "?" + queryString.stringify(query);
      this.refreshList(queryStr, defaultPage);
    }
  }

  handleDelete = (e) => {
    if (!window.confirm("Do you want to delete this item?")){
      return false;
    }
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    const message = "Purchase entry has been deleted successfully.";
    let queryStr = "?" + queryString.stringify(query);
    const itemId = e.currentTarget.dataset.item;
    if(itemId) {
      const userData = JSON.parse(getSession("userData"));
      if(userData) {
        const sessionToken = userData.sessionToken;
        this.props.deletePurchase(
          itemId,
          sessionToken,
          (err, res) => {
            if (!err && res) {
              if(res.status===204) {
                this.showNotification(message);
                this.refreshList(queryStr, defaultPage);
              }
            } else {
              if (err) {
                if(err.response && err.response.status && err.response.status === 403) {
                  setTimeout(() => { 
                    this.setState({isLoading:true});
                    let defaultPage = JSON.parse(getSession("defaultPage"));
                    let url = this.props.location.search;
                    let query = queryString.parse(url);
                    delete query.message;
                    delete query.page;
                    let queryStr = "?" + queryString.stringify(query);
                    this.setState({ activePage: 1 });
                    this.refreshList(queryStr, defaultPage);
                  }, 1000);
                  this.showNotificationError('You are not allowed to delete this purchase entry.');
                } else {
                  this.showNotificationError(err.response);
                }
              }
            }
          }
        );
      } else {
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  }

  handleChangeKind = (e) => {
    this.setState({isLoading:true});
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.kind = value;
    } else {
      delete query.kind;
    }
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ kind: value, activePage: 1 });
    this.refreshList(queryStr, defaultPage);
  }

  handleChangeStockStatus = (e) => {
    this.setState({isLoading:true});
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.status = value;
    } else {
      delete query.status;
    }
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ stockStatus: value, activePage: 1 });
    this.refreshList(queryStr, defaultPage);
  }

  handlePageChange = (pageNumber) => {
    this.setState({isLoading:true});
    let defaultPage = JSON.parse(getSession("defaultPage"));

    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.page = pageNumber;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ activePage: pageNumber });
    this.refreshList(queryStr, defaultPage);
  }

  handleChangePerPage = (e) => {
    this.setState({isLoading:true});
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.size = value;
    } else {
      delete query.size;
    }
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ size: value, activePage: 1 });
    this.refreshList(queryStr, defaultPage);
  }

  handleCheckAll = (e) => {
    let { checked: isChecked } = e.target;
    const checkboxes = document.querySelectorAll('input[name="item"]');
    const checkboxesArr = Array.from(checkboxes);
    if(isChecked) {
      const checked = checkboxesArr.map(item => item.value );
      this.setState({checked:checked});
    } else {
      this.setState({checked:[]});
    }
    checkboxes.forEach(checkbox => {
      if (isChecked) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  }

  handleCheck = (e) => {
    let { value, checked: isChecked } = e.target;
    if(isChecked) {
      this.setState({checked: [...this.state.checked, value]});
    } else {
      const checked = this.state.checked.filter((item) => value !== item);
      this.setState({checked:checked});
    }
  }

  handleDeleteChecked = () => {
    let items = 0;
    let deletedItems = 0;
    let hasError = false;
    const checked = this.state.checked;
    if(checked instanceof Array && checked.length > 0) {
      if (!window.confirm("Are you sure you want to delete the selected item(s)?")){
        return false;
      }
      this.setState({isProcessing:true});
      let defaultPage = JSON.parse(getSession("defaultPage"));
      let url = this.props.location.search;
      let query = queryString.parse(url);
      if(query.message) {
        delete query.message;
      }
      checked.forEach((itemId, index, array) => {
        const userData = JSON.parse(getSession("userData"));
        const sessionToken = userData.sessionToken;
        this.props.deletePurchase(
          itemId,
          sessionToken,
          (err, res) => {
            if(res.status===204) {
              deletedItems++;
            } else {
              if (err) {
                hasError = true;
              }
            }
            items++;
            if(items === array.length) {
              this.setState({isProcessing:false});
              const checkAll = document.getElementById('check-all');
              checkAll.checked = false;
              const checkboxes = document.querySelectorAll('input[name="item"]');
              checkboxes.forEach(checkbox => {
                checkbox.checked = false;
              });
              if(deletedItems > 0) {
                const message = "Purchase entries has been deleted successfully.";
                this.showNotification(message);
                let queryStr = "?" + queryString.stringify(query);
                this.refreshList(queryStr, defaultPage);
              } else {
                if(hasError) {
                  this.showNotificationError('There is a error in deleting the selected item(s)!');
                }
              }
            }
          }
        );
      });
    } else {
      this.showNotificationError('Please select a purchase entry!');
    }
  }

  sortCreated = () => {
    let sort = "";
    if (this.state.sortBy === "created") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "created";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  }

  sortUpdated = () => {
    let sort = "";
    if (this.state.sortBy === "updated") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "updated";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  }

  sortPrice = () => {
    let sort = "";
    if (this.state.sortBy === "price") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "price";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  }

  sortName = () => {
    let sort = "";
    if (this.state.sortBy === "name") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "name";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  }

  sortItemCode = () => {
    let sort = "";
    if (this.state.sortBy === "itemCode") {
      if (this.state.sort !== "desc") {
        sort = "desc";
      } else {
        sort = "asc";
      }
    } else {
      sort = "desc";
    }
    let sortBy = "itemCode";
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.sort = sort;
    query.sortBy = sortBy;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ sort: sort });
    this.setState({ sortBy: sortBy });
    this.refreshList(queryStr, defaultPage);
  }

  
  copyToClipboard = (e) => {
    const itemId = e.currentTarget.dataset.id;
    navigator.clipboard.writeText(itemId)
      .then(() => {
        this.showNotification("ID copied to clipboard!");
      })
      .catch(err => {
        this.showNotificationError("Error in copying ID!");
      });
  }

  refreshList(queryStr, pageId) {
    const query = queryString.parse(queryStr);
    const message = query.message
    const checkAll = document.getElementById('check-all');
    if(checkAll) {
      checkAll.checked = false;
    }
    const checkboxes = document.querySelectorAll('input[name="item"]');
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    if(message) {
      delete query.message;
      this.showNotification(message);
    }

    this.props.history.push("/inventory-level" + queryStr);
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const { sessionToken } = userData;
    if (userData !== null && pageInfo ) {
      this.props.getStocks(
        pageId,
        query,
        sessionToken,
        (err, res) => {
          if (!err && res) {
            this.setState({
              stocks: res.docs,
              pagination: {
                limit: res.pageSize,
                total: res.totalRows
              }
            });
          }
          this.setState({isLoading:false});
        }
      );
    } else {
      this.setState({isLoading:false});
    }
  }

  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "} <Link to="/add-page">here</Link> {" "}to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageNotVerified() {
    return(
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">You Have Not Agreed with our Terms & Policies</h4>
                  <hr />
                  <p className="mb-0">
                    You must agree  with our Terms & Policies. Click {" "} <Link to="/statement-of-agreement">here</Link> {" "} to read our Terms & Policies.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const pageTitle = "Inventory Level";
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if(pageInfo) {
      if (pageInfo.pageType === '5dea2304f6bba08323a3ddce') {
        this.props.history.push("/tours");
        window.location.reload();
      }
    }
    if(pageInfo && pageInfo._id) {
      if(pageInfo.isVerified === true) {
        return (
          <>
            <div className="content">
              <div className="react-notification-alert-container">
                <NotificationAlert ref="notify" />
              </div>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <h4 className="title">{pageTitle} - <em>{pageInfo.name}</em></h4>
                      <Row>
                        <Col md="6">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <label htmlFor="keyword" className="control-label">Search:</label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Input
                                  id="keyword"
                                  name="keyword"
                                  type="text"
                                  placeholder="Search item..."
                                  onChange={this.handleChangeKeyword}
                                  onKeyPress={this.handleEnter}
                                  value={this.state.keyword}
                                >
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row>
                            <Col className="pull-right" md="12">
                              <FormGroup style={{textAlign:'right'}}>
                                <p className="control-label">Filter:</p>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Input
                                  id="kind"
                                  name="kind"
                                  type="select"
                                  onChange={this.handleChangeKind}
                                  value={this.state.kind}
                                >
                                  <option value="">All Kind</option>
                                  <option value="goods">Goods</option>
                                  <option value="crops">Crops</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Input
                                  id="stockStatus"
                                  name="stockStatus"
                                  type="select"
                                  onChange={this.handleChangeStockStatus}
                                  value={this.state.stockStatus}
                                >
                                  <option value="">All Status</option>
                                  <option value="underStock">Under Stock</option>
                                  <option value="outOfStock">Out of Stock</option>
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter table-striped" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>#</th>
                            <th style={{whiteSpace:"nowrap"}}>
                              Item
                            </th>
                            <th style={{whiteSpace:"nowrap"}}>
                              Stock Count
                            </th>
                            <th style={{whiteSpace:"nowrap"}}>
                              Reorder Point
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.renderRows(this.state.stocks)}</tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col md="12">
                          <Row>
                            <Col md="12" lg="12">
                              {this.state.pagination && this.state.pagination.total > 0 && (
                                <>
                                  <Pagination
                                    innerClass="pagination"
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.pagination.limit}
                                    totalItemsCount={this.state.pagination.total}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange}
                                  />
                                  <p>Page <em>{this.state.activePage}</em> of <em>{Math.ceil(this.state.pagination.total/this.state.pagination.limit)}</em> of <em>{numberWithCommasOnly(this.state.pagination.total)}</em> items.</p>
                                  <Input
                                    style={{marginBottom:'5px',width:'auto'}}
                                    id="perPage"
                                    name="perPage"
                                    type="select"
                                    onChange={this.handleChangePerPage}
                                    value={this.state.size}
                                  >
                                    <option value="10">10 / page</option>
                                    <option value="25">25 / page</option>
                                    <option value="50">50 / page</option>
                                    <option value="100">100 / page</option>
                                  </Input>
                                </>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>
            <LoadingOverlay
              active={this.state.isProcessing}
              spinner
              text='Processing...'
              >
            </LoadingOverlay>
            <LoadingOverlay
              active={this.state.isGeneratingXls}
              spinner
              text='Processing...'
              >
            </LoadingOverlay>
          </>
        );
      } else {
        return (this.renderPageNotVerified());
      }
    } else {
      return (this.renderNoPageAdded());
    }
  }
}

const numberWithCommasOnly = x => {
  return priceRound(x,0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const priceRound = (price, dec) => {
  if (dec === undefined) {
    dec = 2;
  }
  if (price !== 0) {
    if (!price || isNaN(price)) {
      throw new Error("price is not a number" + price);
    }
  }
  const str = parseFloat(Math.round(price * 100) / 100).toFixed(dec);
  return str;
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {
    getStocks,
  }
)(InventoryLevel);
